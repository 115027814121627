export default {
    TYPE_USER_ADMIN: "admin",
    TYPE_USER_SELLER: "employee",
    TYPE_CHAIN_SUPPLY: "chain_supply",
    API_URL: "https://stage-api.app-monsieurgaspard.com/",//"https://api.app-monsieurgaspard.com/",//"http://13.38.95.243:3000/",
    API_URL_PROD: "https://api.app-monsieurgaspard.com/",
}

export enum PurchaseStatusSlug{
    CREATING = "en-creation",
    BEFORE_VALIDATION = "a-valider",
    VALIDATED = "validee",
    FABRICATION_IN_PROGRESS = "fabrication-en-cours",
    RECEPTION_FROM_MAKER = "reception-fabricant",
    UNDER_ADJUSTMENT = "en-retouche",
    TRYING_ADJUSTMENT = "essayage-retouche",
    DELIVERED = "livree",
    VALIDATION_IN_PROGRESS = "en-cours-de-validation",
    DELIVERY_IN_PROGRESS = "livraison-en-cours",
}

export enum MeasurementSlug {
    PANTALON = "pantalon",
    VESTE = "veste",
    MANTEAU = "manteau",
    MORPHOLOGY = "morphologie",
    PANTALON_TAILLE = "pantalon-taille",
    PANTALON_LONGUEUR_JAMBE_D = "pantalon-longueur-jambe-d",
    PANTALON_LONGUEUR_JAMBE_G = "pantalon-longueur-jambe-g",
    PANTALON_FOURCHE = "pantalon-fourche",
    VESTE_LONGUEUR_FACE = "veste-longueur-face",
    VESTE_LONGUEUR_DOS = "veste-longueur-dos",
    VESTE_LARGEUR_D_EPAULES = "veste-largeur-d-epaules",
    VESTE_LONGUEUR_BRAS_D = "veste-longueur-bras-d",
    VESTE_LONGUEUR_BRAS_G = "veste-longueur-bras-g",
    MANTEAU_LONGUEUR_FACE = "manteau-longueur-face",
    MANTEAU_LONGUEUR_DOS = "manteau-longueur-dos",
}

export enum ClothingSlug {
    COSTUME_3_PIECES = "costume-3-pieces",
    COSTUME_2_PIECES = "costume-2-pieces",
}

export enum RoleUserEnum {
    HEAD = "head",
    VENDOR = "vendor",
}

export enum TypeUserEnum {
    ADMIN = "admin",
    CHAIN_SUPPLY = "chain_supply",
    EMPLOYEE = "employee",
}

export enum FabricSlug {
    REFERENCE_TISSU = "reference-tissu",
    REFERENCE_BOUTONS_TISSU = "reference-boutons-tissu",
    REFERENCE_BOUTONS = "reference-boutons",
    CHOIX_DE_LA_DOUBLURE = "choix-de-la-doublure",
    REFERENCE_SATIN = "reference-satin",
}

export enum SatinOrTissuSecondaire {
    REFERENCE_SATIN = "-reference-satin",
    REFERENCE_TISSU_SECONDAIRE = "-reference-tissu-secondaire",
}

export enum CodeKutesmart {
    TISSU_PRINCIPAL = "tissu-principal"
}

export enum InputType {
    TEXT = "text",
    BOOLEAN = "boolean",
    SELECT = "select",
    SELECT_MULTIPLE = "select_multiple",
    RADIO = "radio",
    CHECKBOX = "checkbox",
    NUMBER = "number"
}