import { ActionTree, ActionContext } from "vuex";

import { RootState } from "@/store";
import { State } from "./state";
import { Mutations } from './mutations';
import { AdjustmentMutationTypes as MutationTypes } from "./mutation-types";
import { AdjustmentActionTypes as ActionTypes } from "./action-types";
import { IAdjustment, IAdjustmentCommentsPayload, IAdjustmentVersion } from "@/types/IAdjustment.interface";
import adjustmentService from "@/services/adjustment.service";
import { IFilterAndSearchPurchase, IFilterPurchase, IPurchase, IPurchaseToPurchaseStatus } from "@/types/IPurchase.interface";
import purchaseService from "@/services/purchase.service";
import { PurchaseStatusSlug } from "@/constantes/constantes";

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
      key: K,
      payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
  } & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions<S = State> {
    [ActionTypes.SET_ACTIVE_ADJUSTMENT]({ commit }: AugmentedActionContext, payload: IAdjustment): boolean;
    [ActionTypes.SET_ACTIVE_ADJUSTMENT_CUSTOMER_ID]({ commit }: AugmentedActionContext, payload: number): void;
    //[ActionTypes.LOAD_ADJUSTMENTS](augmentedActionContext: AugmentedActionContext): Promise<void>;
    [ActionTypes.UPDATE_ADJUSTMENT](augmentedActionContext: AugmentedActionContext, payload: IAdjustment): Promise<boolean>;
    [ActionTypes.GET_ADJUSTMENTS_FROM_PURCHASE_ID](augmentedActionContext: AugmentedActionContext, payload: number): Promise<boolean>;
    [ActionTypes.SET_FILTERED_PURCHASES_ADJUSTMENT]({ commit }: AugmentedActionContext, payload: IFilterAndSearchPurchase): Promise<void>;
    [ActionTypes.SET_FILTER_AND_SEARCH_ADJUSTMENT](augmentedActionContext: AugmentedActionContext, payload: IFilterAndSearchPurchase): void;
    [ActionTypes.UPDATE_ADJUSTMENT_COMMENTS](augmentedActionContext: AugmentedActionContext, payload: IAdjustmentCommentsPayload): Promise<boolean>;

    [ActionTypes.RESET_ADJUSTMENTS](augmentedActionContext: AugmentedActionContext): Promise<void>;

}

export const actions: ActionTree<State, RootState> & Actions = {
    [ActionTypes.SET_ACTIVE_ADJUSTMENT]({commit}, payload: IAdjustment): boolean {
        commit(MutationTypes.SET_ACTIVE_ADJUSTMENT, payload);
        return true;
    },
    [ActionTypes.SET_ACTIVE_ADJUSTMENT_CUSTOMER_ID]({commit}, payload: number): void {
      commit(MutationTypes.SET_ACTIVE_ADJUSTMENT_CUSTOMER_ID, payload);
  },
    // async [ActionTypes.LOAD_ADJUSTMENTS](augmentedActionContext: AugmentedActionContext): Promise<void> {
    //   await loadAdjustments(augmentedActionContext);
    // },
    async [ActionTypes.UPDATE_ADJUSTMENT](_, payload: IAdjustment): Promise<boolean> {
        const result = await adjustmentService.updateAdjustment(payload);
        //console.log(result);
        if (result && result.length > 0) {
            return true;
        }
        else {
            return false;
        }
    },
    async [ActionTypes.SET_FILTERED_PURCHASES_ADJUSTMENT]({ commit }, payload: IFilterAndSearchPurchase): Promise<void> {
      commit(MutationTypes.SET_FILTER_AND_SEARCH_ADJUSTMENT, payload);
      
      if (payload && payload.filters && payload.filters.find(f => f.isSelected) || payload.searchInput !== '') {
          commit(MutationTypes.SET_FILTER_ACTIVE, true);
          //commit(MutationTypes.SET_FILTERED_PURCHASES_ADJUSTMENT, fPurchases);
      } else {
          commit(MutationTypes.SET_FILTER_ACTIVE, false);
        
      }
  },
  async [ActionTypes.INIT_PURCHASE_ADJUSTMENT_FILTERS](augmentedActionContext: AugmentedActionContext): Promise<void> {
      initFilters(augmentedActionContext);
  },
  async [ActionTypes.GET_ADJUSTMENTS_FROM_PURCHASE_ID](augmentedActionContext: AugmentedActionContext, payload: number): Promise<boolean> {
    if (payload > 0) {
        // const adjustment = await adjustmentService.getAjustmentsByPurchaseId(payload);
        return adjustmentService.getAjustmentsByPurchaseId(payload).then(adjustment => {
            augmentedActionContext.commit(MutationTypes.LOAD_ADJUSTMENTS_FROM_PURCHASE_ID, adjustment);
            return true;
        });
    } else {
        return new Promise((resolve) => resolve(false));
    }
  },
  [ActionTypes.SET_FILTER_AND_SEARCH_ADJUSTMENT](augmentedActionContext: AugmentedActionContext, payload: IFilterAndSearchPurchase) {
    augmentedActionContext.commit(MutationTypes.SET_FILTER_AND_SEARCH_ADJUSTMENT, payload);
  },
  async [ActionTypes.UPDATE_ADJUSTMENT_COMMENTS](augmentedActionContext: AugmentedActionContext, payload: IAdjustmentCommentsPayload): Promise<boolean> {
    const result = await adjustmentService.updateComments(payload);
    if (result) {
        augmentedActionContext.commit(MutationTypes.UPDATE_ADJUSTMENT_COMMENTS, payload);
    }
    return result;
  },
    
  async [ActionTypes.RESET_ADJUSTMENTS](augmentedActionContext: AugmentedActionContext): Promise<void> {
      await loadAdjustments(augmentedActionContext);

  },
}

async function loadAdjustments(augmentedActionContext: AugmentedActionContext): Promise<void> {
    //augmentedActionContext.commit(MutationTypes.LOAD_ADJUSTMENTS, [] as IAdjustment[]);
    augmentedActionContext.commit(MutationTypes.SET_ACTIVE_ADJUSTMENT, {} as IAdjustment);
    //augmentedActionContext.commit(MutationTypes.SET_FILTERED_PURCHASES_ADJUSTMENT, [] as IPurchase[]);
    augmentedActionContext.commit(MutationTypes.SET_FILTER_ACTIVE, false);
    augmentedActionContext.commit(MutationTypes.INIT_PURCHASE_ADJUSTMENT_FILTERS, [] as IFilterPurchase[]);
    augmentedActionContext.commit(MutationTypes.LOAD_ADJUSTMENTS_FROM_PURCHASE_ID, {} as IAdjustment);

}

async function initFilters(augmentedActionContext: AugmentedActionContext): Promise<void> {
  const status = await purchaseService.getPurchaseStatus().then(data => {
    return data.filter(x => x.id >= 5 && x.id < 9);
  });

  const filters = [
    {
        id: 1,
        label: "Alerte",
        slug: "isAlert",
        type: "boolean",
        isSelected: false
    },
      {
          id: 2,
          label: "Urgence",
          slug: "isUrgent",
          type: "boolean",
          isSelected: false
      },
      {
          id: 3,
          label: "SAV",
          slug: "isSav",
          type: "boolean",
          isSelected: false
      },
      {
        id: 4,
        label: "Deadline",// (+ récente à la plus ancienne)",
        slug: "deadline",
        type: "sort",
        isSelected: false
    },
    // {
    //     id: 4,
    //     label: "Date d'achat (+ récente)",
    //     slug: "createdAt",
    //     type: "sort",
    //     isSelected: false
    // },
    {
        id: 5,
        label: "Date de retouche (+ ancienne)",
        slug: "createdAt3",
        type: "sort",
        isSelected: false
    },
    {
        id: 6,
        label: "Date d'achat (+ ancienne)",
        slug: "createdAt2",
        type: "sort",
        isSelected: false
    },
    {
        id: 7,
        label: "Date de retour (+ proche)",
        slug: "returnDate",
        type: "sort",
        isSelected: false
    },
  ] as IFilterPurchase[];

  let index = 7;

  if (status && status.length > 0) {
    const _status = status.sort((a, b) => a.orderFilter - b.orderFilter);
    for (const item of _status) {
        if (item.displayFilter) {
        index = index + 1;
        filters.push({
            id: index,
            label: item.label,
            slug: item.slug,
            idSlug: item.id,
            type: "status",
            isSelected: false
        });
        }
    }
  }

  //console.log("initFilters", filters);

  augmentedActionContext.commit(MutationTypes.INIT_PURCHASE_ADJUSTMENT_FILTERS, filters);
  augmentedActionContext.commit(MutationTypes.SET_FILTER_AND_SEARCH_ADJUSTMENT, {
    filters,
    searchInput: '',
    nbSearch: 0,
});
}

function sortFilteredPurchases(purchases: IPurchase[], filters: IFilterPurchase[]) {
  const sortFilters = filters.filter(f => f.type === "sort" && f.isSelected === true);
  let result = [...purchases];
  //console.log("------ result before ----", result);
  for (const item of sortFilters) {
      switch (item.slug) {
        case "createdAt":
            result = [...purchases.filter(filterPurchaseNoBuyingDate).sort(sortPurchaseByBuyingDateDesc)];
            break;
        case "createdAt2":
            result = [...purchases.filter(filterPurchaseNoBuyingDate).sort(sortPurchaseByBuyingDateAsc)];
            //console.log("------ result after ----", result);
            break;
        case "createdAt3":
            result = [...purchases.sort(sortPurchaseByCreationDateDesc)];
            break;
        case "deadline":
            const tempPurchases = [...purchases.filter(p => p.deadlineDate !== null && p.deadlineDate !== "" && (new Date(p.deadlineDate)).getTime() >= Date.now())];
            //console.log("tempPurchases ", tempPurchases);
            result = [...tempPurchases.sort((a,b) => {
                // if (b.deadlineDate < a.deadlineDate) {
                //     return -1
                // } else if (b.deadlineDate === a.deadlineDate) {
                //     return 0
                // } else {
                //     return 1;
                // }
                return (new Date(a.deadlineDate)).getTime() - (new Date(b.deadlineDate)).getTime();
            })]
            break;
        case "productionDate":
            result = [...purchases.sort((a,b) => {
                // if (b.productionDate < a.productionDate) {
                //     return -1
                // } else if (b.productionDate === a.productionDate) {
                //     return 0
                // } else {
                //     return 1;
                // }
                return (new Date(b.productionDate)).getTime() - (new Date(a.productionDate)).getTime();
            })]
            break;
        case "returnDate":
            result = [...purchases.filter(filterPurchaseNoReturnDate).sort(sortPurchaseByReturnDateDesc)];
            //console.log("------ result after returnDate ----", result);
            break;
          default:
            result = [...purchases.sort(sortPurchaseByCreatedAtAdjustementDateDesc)];
            break;
      }
  }

  return result;
}

function purchaseStatus(purchase: IPurchase): IPurchaseToPurchaseStatus {
  const _activePurchase = purchase as IPurchase;
  let _date = "";
  let result : IPurchaseToPurchaseStatus = {} as IPurchaseToPurchaseStatus;

  for (const status of _activePurchase.purchaseToPurchaseStatus) {
      if (_date < status.updatedAt) {
          _date = status.updatedAt;
          result = status;
      }
  }

  return result;
}

function sortPurchaseByBuyingDateDesc(p1: IPurchase, p2: IPurchase) {
    const p1BuyingDate = p1.purchaseToPurchaseStatus.find(ptps => ptps.purchaseStatus.slug === "validee")?.createdAt;
    const p2BuyingDate = p2.purchaseToPurchaseStatus.find(ptps => ptps.purchaseStatus.slug === "validee")?.createdAt;
    if (!p1BuyingDate && !p2BuyingDate) {
        return 0;
    } else if (p1BuyingDate && !p2BuyingDate) {
        return -1;
    } else if (!p1BuyingDate && p2BuyingDate) {
        return 1;
    } else if (p1BuyingDate && p2BuyingDate) {
        return (new Date(p2BuyingDate)).getTime() - (new Date(p1BuyingDate)).getTime();
    } else {
        return 0;
    }
}

function sortPurchaseByCreatedAtAdjustementDateDesc(p1: IPurchase, p2: IPurchase): number {
    const p1CreatedAtDate = p1.ihmAdjustmentCreatedAt;
    const p2CreatedAtDate = p2.ihmAdjustmentCreatedAt;
    let result = 0;
    if ((!p1CreatedAtDate || p1CreatedAtDate === '') && (!p2CreatedAtDate || p2CreatedAtDate === '')) {
        result = 0;
    } else if (p1CreatedAtDate && (!p2CreatedAtDate || p2CreatedAtDate === '')) {
        result = -1;
    } else if ((!p1CreatedAtDate || p1CreatedAtDate === '') && p2CreatedAtDate) {
        result = 1;
    } else if (p1CreatedAtDate && p2CreatedAtDate) {
        result = (new Date(p2CreatedAtDate)).getTime() - (new Date(p1CreatedAtDate)).getTime();
    } else {
        result = 0;
    }
    //console.log("sortPurchaseByCreatedAtAdjustementDateDesc", result, p1CreatedAtDate, p2CreatedAtDate, p1, p2);
    return result;
}

function sortPurchaseByBuyingDateAsc(p1: IPurchase, p2: IPurchase) {
    const p1BuyingDate = p1.purchaseToPurchaseStatus.find(ptps => ptps.purchaseStatus.slug === "validee")?.createdAt;
    const p2BuyingDate = p2.purchaseToPurchaseStatus.find(ptps => ptps.purchaseStatus.slug === "validee")?.createdAt;
    if (!p1BuyingDate && !p2BuyingDate) {
        return 0;
    } else if (p1BuyingDate && !p2BuyingDate) {
        return 1;
    } else if (!p1BuyingDate && p2BuyingDate) {
        return -1;
    } else if (p1BuyingDate && p2BuyingDate) {
        return (new Date(p1BuyingDate)).getTime() - (new Date(p2BuyingDate)).getTime();
    } else {
        return 0;
    }
}

function sortPurchaseByCreationDateDesc(p1: IPurchase, p2: IPurchase) {
    const p1CreatedAtDate = p1.ihmAdjustmentCreatedAt;
    const p2CreatedAtDate = p2.ihmAdjustmentCreatedAt;
    let result = 0;
    if ((!p1CreatedAtDate || p1CreatedAtDate === '') && (!p2CreatedAtDate || p2CreatedAtDate === '')) {
        result = 0;
    } else if (p1CreatedAtDate && (!p2CreatedAtDate || p2CreatedAtDate === '')) {
        result = -1;
    } else if ((!p1CreatedAtDate || p1CreatedAtDate === '') && p2CreatedAtDate) {
        result = 1;
    } else if (p1CreatedAtDate && p2CreatedAtDate) {
        result = (new Date(p1CreatedAtDate)).getTime() - (new Date(p2CreatedAtDate)).getTime();
    } else {
        result = 0;
    }
    //console.log("sortPurchaseByCreatedAtAdjustementDateDesc", result, p1CreatedAtDate, p2CreatedAtDate, p1, p2);
    return result;
}

function sortPurchaseByReturnDateDesc(p1: IPurchase, p2: IPurchase): number {
    return (new Date(p1.ihmAdjustmentReturnAdjustDate)).getTime() - (new Date(p2.ihmAdjustmentReturnAdjustDate)).getTime(); 
}

function filterPurchaseNoBuyingDate(p: IPurchase) {
    const buyingDate = p.purchaseToPurchaseStatus.find(ptps => ptps.purchaseStatus.slug === "validee")?.createdAt;
    if (!buyingDate) {
        return false;
    } else {
        return true;
    }
}

function filterPurchaseNoReturnDate(p: IPurchase) {
    const returnDate = p.ihmAdjustmentReturnAdjustDate;
    const _date = new Date(returnDate);
    if (!returnDate || returnDate === "" || (Date.now() - _date.getTime()) > 0) {
        return false;
    } else {
        return true;
    }
}

function filterPurchaseNoCreationDate(p: IPurchase) {
    const creationDate = p.purchaseToPurchaseStatus.find(ptps => ptps.purchaseStatus.slug === "en-creation")?.createdAt;
    if (!creationDate) {
        return false;
    } else {
        return true;
    }
}