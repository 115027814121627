import constantes from "@/constantes/constantes";
import {
  IClothing,
  IClothingDetails,
  IClothingDisplayRules,
  IClothingOption,
  IClothingOptionType,
  IClothingType,
} from "@/types/IClothing.interface";
import toolsService from "./tools.service";

const apiUrlClothing = toolsService.getApiUrl()+ "v1/clothing";

export default {
  async getClothings(): Promise<IClothing[]> {
    if (toolsService.isUserConnected()) {

      const requestOptions = toolsService.getRequestOption("GET", "");

      //return fetch(apiUrlClothing, requestOptions).then(Response => Response.json()).then(data => {
      return toolsService.fetchRessource(apiUrlClothing, requestOptions, {}, "clothing.service.ts", "getClothings").then(data => {
        return (data as IClothing[]).filter(c => c.order > 0).sort((a, b) => {
              return a.order - b.order;
            });
      });
    } else {
      return new Promise(resolve => resolve([] as IClothing[]));
    }
  },

  async getClothingDetails(idClothing: number): Promise<IClothingDetails> {
    return new Promise(async (resolve, reject) => {
      let result: IClothingDetails = {} as IClothingDetails;

      const requestOptions = toolsService.getRequestOption("GET", "");

      //result = await fetch(apiUrlClothing + "/products/" + idClothing, requestOptions).then(Response => Response.json());
      result = await toolsService.fetchRessource(apiUrlClothing + "/products/" + idClothing, requestOptions, idClothing, "clothing.service.ts", "getClothingDetails");

      if (!result.clothingOptionType) {
        result.clothingOptionType = [] as IClothingOptionType[];
      }

      // to be able to have 2 or more options on the same line when they have the same group name
      for (const optionType of result.clothingOptionType) {
        for (const option of optionType.clothingOption) {
          if (
            option.rules.metadata.groupName !== null &&
            option.ihmItemUsed !== true
          ) {
            option.ihmItemsOnTheSameLine = [];
            for (const option2 of optionType.clothingOption) {
              if (
                option2.id !== option.id &&
                option2.ihmItemUsed !== true &&
                option2.rules.metadata.groupName ===
                  option.rules.metadata.groupName
              ) {
                option.ihmItemsOnTheSameLine.push(option2);
                option2.ihmItemUsed = true;
              }
            }
          }
        }
      }
      

      if (result.assembly !== null && result.assembly[0] !== result.id) {
        // console.log("on est sur une assembly !!");

        // first call to get the elements that are in common
        const commonDetails = await this.getClothingDetails(result.assembly[0]);
        result.clothingOptionType.push(...commonDetails.clothingOptionType.filter(cot => cot.rules.isCommonAssembly === true));

        for (const assembly of result.assembly) {
          const assemblyDetails = await this.getClothingDetails(assembly);
          //console.log("assemblyDetails => ", assemblyDetails);
          result.clothingOptionType.push({
            id: 0,
            createdAt: "",
            updatedAt: "",
            label: assemblyDetails.label,
            slug: assemblyDetails.label + "_separator" + "_" + assemblyDetails.id,
            order: 0,
            rules: {} as IClothingDisplayRules,
            clothingOption: [],
            ihmIdAssembly: assembly,
            clothingId: 0,
            active: assemblyDetails.active
          } as IClothingOptionType);


          for (const optionType of assemblyDetails.clothingOptionType)//.filter(cot => cot.rules.isCommonAssembly !== true))
          {
            optionType.ihmIdAssembly = assembly;
            result.clothingOptionType.push(optionType);
          }
        }
      }
      resolve(result);
    });
  },

  async getClothingTypes(): Promise<IClothingType[]> {
    if (toolsService.isUserConnected()) {
      const requestOptions = toolsService.getRequestOption("GET", "");

      return toolsService.fetchRessource(apiUrlClothing + "/types", requestOptions, {}, "clothing.service.ts", "getClothingTypess");
    } else {
      return new Promise(resolve => resolve([] as IClothingType[]));
    }
  },

  async updateClothingOptionType(clothingOptionType: any): Promise<void> {
    console.log("updateClothingOptionType", clothingOptionType);
      const requestOptions = toolsService.getRequestOption("PATCH", JSON.stringify(clothingOptionType));
      await toolsService.fetchRessourceWithoutAnswer(toolsService.getApiUrl() + "v1/form/clothingOptionType", requestOptions, clothingOptionType, "clothing.service.ts", "updateClothingOptionType");
      console.log("updateClothingOptionType terminé");
  },
  async updateClothingOption(clothingOption: any): Promise<void> {
    console.log("updateClothingOption", clothingOption);
      const requestOptions = toolsService.getRequestOption("PATCH", JSON.stringify(clothingOption));
      await toolsService.fetchRessourceWithoutAnswer(toolsService.getApiUrl() + "v1/form/clothingOption", requestOptions, clothingOption, "clothing.service.ts", "updateClothingOption");
      console.log("updateClothingOption terminé");
  },
  async updateClothingOptionValue(clothingOptionValue: any): Promise<void> {
    console.log("updateClothingOptionValue", clothingOptionValue);
      const requestOptions = toolsService.getRequestOption("PATCH", JSON.stringify(clothingOptionValue));
      await toolsService.fetchRessourceWithoutAnswer(toolsService.getApiUrl() + "v1/form/clothingOptionValue", requestOptions, clothingOptionValue, "clothing.service.ts", "updateClothingOptionValue");
      console.log("updateClothingOptionValue terminé");
  },
};
