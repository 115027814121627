import constantes from "@/constantes/constantes";
import { IAdjustment, IAdjustmentCommentsPayload, IAdjustmentResult } from "@/types/IAdjustment.interface";
import toolsService from "./tools.service";

export default {
    async updateAdjustment(adjustment: IAdjustment): Promise<IAdjustmentResult[]> {
        const _adjustment = formatAdjustment(adjustment);
        const requestOptions = toolsService.getRequestOption("PATCH", JSON.stringify(_adjustment));
        return toolsService.fetchRessource(toolsService.getApiUrl() + "v1/adjustment/" + adjustment.id, requestOptions, adjustment,  "adjustment.service.ts", "updateAdjustment");
    },
    async getAjustmentsByPurchaseId(purchaseId: number): Promise<IAdjustment> {
        const requestOptions = toolsService.getRequestOption("GET", "");
        return toolsService.fetchRessource(toolsService.getApiUrl() + "v1/adjustment/" + purchaseId, requestOptions, purchaseId,  "adjustment.service.ts", "getAjustmentsByPurchaseId");
    },
    async updateComments(payload: IAdjustmentCommentsPayload): Promise<boolean> {
        const requestOptions = toolsService.getRequestOption("POST", JSON.stringify(payload));
        try {
            const result = await toolsService.fetchRessource(toolsService.getApiUrl() + "v1/adjustment/comment", requestOptions, payload, "adjustment.service.ts", "updateComments");
            console.log('result => ', result);
            if (result && result.message && result.message !== '') {
                return new Promise((resolve) => resolve(false));
            }
            return new Promise((resolve) => resolve(true));
        } catch(error) {
            return new Promise((resolve) => resolve(false));
        }
        
    }
}

function formatAdjustment(payload: IAdjustment): unknown {
    const _adjustment: unknown = {
        returnAdjustDate: payload.returnAdjustDate,
        comments: payload.comments,
        customerId: payload.customerId,
        userAdjustmentId: payload.userAdjustmentId,
        shopId: payload.shopId,
        userId: payload.userId,
        isUrgent: payload.isUrgent,
        adjustment: {
            version: payload.adjustmentVersion.version,
            adjustmentDetail: [...payload.adjustmentVersion.adjustmentDetail]
        }
    } 
    return _adjustment;
}