export interface IStock {
    id: number,
    createdAt: string,
    updatedAt: string,
    reference: string,
    quantity: string,
    arrival: string,
    endOfLife: string,
    type: StockType,
    error: string,
    unit: string | null,
    couleur: string | null,
    style: string | null,
    gamme: string | null,
    prixCH: string | null,
    prixC3: string | null,
    prixC2: string | null,
    prixVE: string | null,
    prixGI: string | null,
    prixPA: string | null,
    prixMA: string | null,
}

export enum StockType {
    TISSU = "tissu",
    DOUBLURE = "doublure",
    SATIN = "satin",
    FEUTRE = "feutre",
    BOUTON = "bouton",
    NON_CONCERNE = "non_concerne",
}


export interface IFabricFieldInfo {
    isFabricField: boolean,
    typeStock: StockType
}