import { ActionTree, ActionContext } from "vuex";

import { RootState } from "@/store";
import { state, State } from "./state";
import { mutations, Mutations } from './mutations';
import { Getters } from "./getters";
import { PurchaseMutationTypes as MutationTypes } from "./mutation-types";
import { PurchaseActionTypes as ActionTypes } from "./action-types";

import purchaseService from "@/services/purchase.service";
import { ICreatePurchase, IFilterAndSearchPurchase, IFilterPurchase, IPurchase, IPurchaseCommentsPayload, IPurchaseDetail, IPurchaseDetailsReceive, IPurchaseSaveStatus, IPurchaseToPurchaseStatus } from "@/types/IPurchase.interface";
import { IUser } from "@/types/IUser.interface";
import { ICustomer } from "@/types/ICustomer.interface";
import { IAdjustmentVersion } from "@/types/IAdjustment.interface";

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
      key: K,
      payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
    getters: {
        [K in keyof Getters]: ReturnType<Getters[K]>
      };
  } & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions<S = State> {
    [ActionTypes.ADD_ITEM_TO_PURCHASE]({ commit }: AugmentedActionContext, payload: IPurchaseDetail): number;
    [ActionTypes.UPDATE_ITEM_IN_PURCHASE]({ commit }: AugmentedActionContext, payload: IPurchaseDetail): void; 
    [ActionTypes.REMOVE_ITEM_FROM_PURCHASE]({ commit }: AugmentedActionContext, payload: IPurchaseDetail): void;
    [ActionTypes.SET_USER_ON_PURCHASE]({ commit }: AugmentedActionContext, payload: IUser): void;
    [ActionTypes.SET_CUSTOMER_ON_PURCHASE]({ commit }: AugmentedActionContext, payload: ICustomer): void;
    [ActionTypes.CREATE_NEW_PURCHASE]({ commit, getters }: AugmentedActionContext, payload: IPurchase): Promise<void>;
    [ActionTypes.CREATE_NEW_PURCHASE_ORDER_AGAIN]({ commit, getters }: AugmentedActionContext, payload: IPurchase): Promise<void>;
    [ActionTypes.SET_ACTIVE_PURCHASE]({ commit }: AugmentedActionContext, payload: IPurchase): Promise<boolean>;
    [ActionTypes.SET_ACTIVE_PURCHASE_DETAIL]({ commit }: AugmentedActionContext, payload: IPurchaseDetail): void;
    [ActionTypes.DELETE_PURCHASE]({ commit }: AugmentedActionContext, payload: IPurchase): Promise<boolean>;
    [ActionTypes.LOAD_PURCHASES](augmentedActionContext: AugmentedActionContext): void;
    [ActionTypes.SAVE_PURCHASE]({ commit }: AugmentedActionContext, payload: IPurchase): Promise<number>;
    [ActionTypes.RESET_PURCHASE](augmentedActionContext: AugmentedActionContext): void;
    [ActionTypes.TOGGLE_DISPLAY_IMAGES]({ commit }: AugmentedActionContext): void;
    [ActionTypes.SET_FILTERED_PURCHASES]({ commit }: AugmentedActionContext, payload: IFilterAndSearchPurchase): void;
    [ActionTypes.SET_PURCHASE_STATUS]({ commit }: AugmentedActionContext, payload: IPurchaseSaveStatus): Promise<boolean>;
    [ActionTypes.RECEIVE_PURCHASE](augmentedActionContext: AugmentedActionContext, payload: IPurchaseDetail[]): Promise<boolean>;
    [ActionTypes.FETCH_PURCHASE_DETAILS](augmentedActionContext: AugmentedActionContext, payload: number): Promise<IPurchaseDetail>;
    [ActionTypes.UPDATE_PURCHASE_COMMENTS](augmentedActionContext: AugmentedActionContext, payload: IPurchaseCommentsPayload): Promise<boolean>;
}

export const actions: ActionTree<State, RootState> & Actions = {
    [ActionTypes.ADD_ITEM_TO_PURCHASE]({commit}, item: IPurchaseDetail): number {
        commit(MutationTypes.ADD_ITEM_TO_PURCHASE, item);
        return item.id;
    },
    [ActionTypes.UPDATE_ITEM_IN_PURCHASE]({commit}, item: IPurchaseDetail): void {
        commit(MutationTypes.REMOVE_ITEM_FROM_PURCHASE, item.id);
        commit(MutationTypes.ADD_ITEM_TO_PURCHASE, item);
    },
    async [ActionTypes.REMOVE_ITEM_FROM_PURCHASE]({commit}, item: IPurchaseDetail): Promise<void> {
        //console.log("REMOVE_ITEM_FROM_PURCHASE => ", item.id);
        commit(MutationTypes.REMOVE_ITEM_FROM_PURCHASE, item.id);
    },
    [ActionTypes.SET_USER_ON_PURCHASE]({ commit }, user: IUser): void {
        commit(MutationTypes.SET_USER_ON_PURCHASE, user);
    },
    [ActionTypes.SET_CUSTOMER_ON_PURCHASE]({ commit }, customer: ICustomer): void {
        commit(MutationTypes.SET_CUSTOMER_ON_PURCHASE, customer);
    },
    async [ActionTypes.CREATE_NEW_PURCHASE]({ commit}, newPurchaseInfos: IPurchase): Promise<void> {
        const purchase = {
            id: 0,
            cause: "",
            customerId: newPurchaseInfos.customerId,
            userId: newPurchaseInfos.userId,
            shopId: newPurchaseInfos.shopId,
            purchaseDetail: [] as IPurchaseDetail[],
            purchaseToPurchaseStatus: [] as IPurchaseToPurchaseStatus[],
            isOld: newPurchaseInfos.isOld
        } as IPurchase;

        //console.log("purchase => ", purchase);
        //const _purchase = await purchaseService.createPurchase(purchase);
        //commit(MutationTypes.CREATE_NEW_PURCHASE, purchase);
        commit(MutationTypes.SET_ACTIVE_PURCHASE, purchase);
    },
    async [ActionTypes.CREATE_NEW_PURCHASE_ORDER_AGAIN]({ commit}, newPurchaseInfos: IPurchase): Promise<void> {
        const purchase = {
            id: 0,
            cause: "",
            customerId: newPurchaseInfos.customerId,
            userId: newPurchaseInfos.userId,
            shopId: newPurchaseInfos.shopId,
            purchaseDetail: [...newPurchaseInfos.purchaseDetail],
            purchaseToPurchaseStatus: [] as IPurchaseToPurchaseStatus[],
        } as IPurchase;
        //const _purchase = await purchaseService.createPurchase(purchase);
        //commit(MutationTypes.CREATE_NEW_PURCHASE, purchase);
        commit(MutationTypes.SET_ACTIVE_PURCHASE, purchase);
    },
    async [ActionTypes.SET_ACTIVE_PURCHASE]({ commit }, payload: IPurchase): Promise<boolean> {
        if (payload && payload.id) {
            const purchase = await purchaseService.getPurchase(payload.id);
            commit(MutationTypes.SET_ACTIVE_PURCHASE, purchase);
        } else {
            commit(MutationTypes.SET_ACTIVE_PURCHASE, payload);
        }
        return new Promise(resolve => resolve(true));
    },
    [ActionTypes.SET_ACTIVE_PURCHASE_DETAIL]({ commit }, payload: IPurchaseDetail): void {
        commit(MutationTypes.SET_ACTIVE_PURCHASE_DETAIL, payload);
    },
    async [ActionTypes.DELETE_PURCHASE](augmentedActionContext: AugmentedActionContext, payload: IPurchase): Promise<boolean> {
        if (payload && payload.id) {
            const result = await purchaseService.deletePurchase(payload);
            // if (result) {
            //     augmentedActionContext.commit(MutationTypes.DELETE_PURCHASE, payload);
            // }
            //await loadPurchases(augmentedActionContext);
            return result;
        }
        return false;
    },
    async [ActionTypes.LOAD_PURCHASES](augmentedActionContext: AugmentedActionContext): Promise<void> {
        //await loadPurchases(augmentedActionContext);
    },
    async [ActionTypes.SAVE_PURCHASE]({ commit }, purchase: IPurchase): Promise<number> {
        let _purchase: IPurchase;
        if (purchase.id === 0) {
            let _purchase = await purchaseService.createPurchase(purchase);
            //commit(MutationTypes.DELETE_PURCHASE, purchase);
            purchase.id = _purchase.id;
        }
        _purchase = await purchaseService.updatePurchase(purchase);
        //commit(MutationTypes.ADD_PURCHASE, _purchase);
        return _purchase.id;
    },
    async [ActionTypes.RESET_PURCHASE](augmentedActionContext: AugmentedActionContext): Promise<void> {
        // augmentedActionContext.commit(MutationTypes.LOAD_PURCHASES, [] as IPurchase[]);
        // augmentedActionContext.commit(MutationTypes.INIT_PURCHASE_FILTERS, [] as IFilterPurchase[]);
        // augmentedActionContext.commit(MutationTypes.SET_FILTERED_PURCHASES, [] as IPurchase[]);
        // augmentedActionContext.commit(MutationTypes.SET_FILTER_ACTIVE, false);
        // await loadPurchases(augmentedActionContext);
        // await initFilters(augmentedActionContext);
        augmentedActionContext.commit(MutationTypes.RESET_PURCHASE, undefined);
        // augmentedActionContext.commit(MutationTypes.SET_FILTER_AND_SEARCH_PURCHASE, {
        //     filters: [],
        //     searchInput: '',
        //     nbSearch: 0,
        // } as IFilterAndSearchPurchase);
        // initFilters(augmentedActionContext);
    },
    [ActionTypes.TOGGLE_DISPLAY_IMAGES]({ commit }): void {
        commit(MutationTypes.TOGGLE_DISPLAY_IMAGE, undefined);
    },
    async [ActionTypes.SET_FILTERED_PURCHASES](augmentedActionContext: AugmentedActionContext, payload: IFilterAndSearchPurchase): Promise<void> {
        augmentedActionContext.commit(MutationTypes.SET_FILTER_AND_SEARCH_PURCHASE, payload);
        if (payload && payload.filters && payload.filters.find(f => f.isSelected) || payload.searchInput !== '') {
            // at least one filter is active
            // let purchases : IPurchase[];
            // if (payload.find(f => (f.type === "slug" || f.type === "boolean"))) {
            //     purchases =  await purchaseService.getFilteredPurchases(payload);
            // } else {
            //     purchases = await purchaseService.loadPurchases();
            // }

            //const fPurchases = sortFilteredPurchases(purchases, payload);
            //console.log("fPurchases => " ,fPurchases);
            
            augmentedActionContext.commit(MutationTypes.SET_FILTER_ACTIVE, true);
            //augmentedActionContext.commit(MutationTypes.LOAD_PURCHASES, fPurchases);
        } else {
            augmentedActionContext.commit(MutationTypes.SET_FILTER_ACTIVE, false);
            //await loadPurchases(augmentedActionContext);
        }
    },
    async [ActionTypes.INIT_PURCHASE_FILTERS](augmentedActionContext: AugmentedActionContext): Promise<void> {
        initFilters(augmentedActionContext);
    },
    async [ActionTypes.SET_PURCHASE_STATUS]({commit}: AugmentedActionContext, payload: IPurchaseSaveStatus): Promise<boolean> {
        commit(MutationTypes.SET_ACTION_IN_PROGRESS, true);
        const result = purchaseService.savePurchaseStatus(payload).then(async data => {
            
            const purchase = await purchaseService.getPurchase(payload.purchaseId);
            // const test = await purchaseService.fetchPurchaseStatus(payload.purchaseId);
            // const purchase2 = await purchaseService.getPurchase(payload.purchaseId);
            // console.log("fetchPurchaseStatus", test, purchase, purchase2);
            //console.log("SET_PURCHASE_STATUS", purchase);
            commit(MutationTypes.SET_ACTIVE_PURCHASE, purchase);
            commit(MutationTypes.SET_STATUS_PURCHASE_UPDATED, undefined);
            return data;
        });
        console.log("ActionTypes.SET_PURCHASE_STATUS => ", result);
        const purchase = await purchaseService.getPurchase(payload.purchaseId);
        // const test = await purchaseService.fetchPurchaseStatus(payload.purchaseId);
        // const purchase2 = await purchaseService.getPurchase(payload.purchaseId);
        // console.log("fetchPurchaseStatus", test, purchase, purchase2);
        //console.log("SET_PURCHASE_STATUS", purchase);
        commit(MutationTypes.SET_ACTIVE_PURCHASE, purchase);
        commit(MutationTypes.SET_ACTION_IN_PROGRESS, false);
        return result;
        
    },
    async [ActionTypes.RECEIVE_PURCHASE](augmentedActionContext: AugmentedActionContext, payload: IPurchaseDetail[]): Promise<boolean> {
        let purchaseId = 0;
        //console.log("ActionTypes.RECEIVE_PURCHASE => ", payload);
        for (const item of payload) {
            purchaseId = item.purchaseId;
            const input = {
                purchaseId: item.purchaseId,
                purchaseDetailId: item.id,
                quantity: item.quantityReceived
            } as IPurchaseDetailsReceive;
            await purchaseService.receivePurchaseDetail(input);
        }
        if (purchaseId !== 0) {
            const purchase = await purchaseService.getPurchase(purchaseId);
            augmentedActionContext.commit(MutationTypes.SET_ACTIVE_PURCHASE, purchase);
        }
        return true;
    },
    async [ActionTypes.FETCH_PURCHASE_DETAILS](augmentedActionContext: AugmentedActionContext, payload: number): Promise<IPurchaseDetail> {
        return purchaseService.fetchPurchaseStatus(payload);
        
    },
    async [ActionTypes.UPDATE_PURCHASE_COMMENTS](augmentedActionContext: AugmentedActionContext, payload: IPurchaseCommentsPayload): Promise<boolean> {
        const result = await purchaseService.updateComments(payload);
        if (result) {
            augmentedActionContext.commit(MutationTypes.UPDATE_PURCHASE_COMMENTS, payload);
        }
        return result;
    }
}

async function initFilters(augmentedActionContext: AugmentedActionContext): Promise<void> {
    const status = await purchaseService.getPurchaseStatus();
    const filters = [
        {
            id: 1,
            label: "Alerte",
            slug: "isAlert",
            type: "boolean",
            isSelected: false
        },
        {
            id: 2,
            label: "Urgence",
            slug: "isUrgent",
            type: "boolean",
            isSelected: false
        },
        {
            id: 3,
            label: "SAV",
            slug: "isSav",
            type: "boolean",
            isSelected: false
        },
        {
            id: 4,
            label: "Deadline",// (+ récente à la plus ancienne)",
            slug: "deadline",
            type: "sort",
            isSelected: false
        },
        // {
        //     id: 4,
        //     label: "Date d'achat (+ récente)",
        //     slug: "createdAt",
        //     type: "sort",
        //     isSelected: false
        // },
        {
            id: 5,
            label: "Date d'achat (+ ancienne)",
            slug: "createdAt2",
            type: "sort",
            isSelected: false
        },
        
        // {
        //     id: 5,
        //     label: "Date de fabrication (+ récente à la plus ancienne)",
        //     slug: "productionDate",
        //     type: "sort",
        //     isSelected: false
        // },
    ] as IFilterPurchase[];

    let index = 5;

    if (status && status.length > 0) {
        const _status = status.sort((a, b) => a.orderFilter - b.orderFilter);
        for (const item of _status) {
            if (item.displayFilter) {
                index = index + 1;
                filters.push({
                    id: index,
                    label: item.label,
                    slug: item.slug,
                    idSlug: item.id,
                    type: "status",
                    isSelected: false
                });
            }
        }
    }

    //console.log("initFilters", filters);

    augmentedActionContext.commit(MutationTypes.INIT_PURCHASE_FILTERS, filters);
    augmentedActionContext.commit(MutationTypes.SET_FILTER_AND_SEARCH_PURCHASE, {
        filters,
        searchInput: '',
        nbSearch: 0,
    });
}
