import { MutationTree } from "vuex";

import { ICustomer, ICustomerReferential, ICustomerToMeasurement } from "@/types/ICustomer.interface"; 

import { State } from "./state";
import { CustomerMutationTypes as MutationTypes } from './mutation-types';
import { IFilterAndSearchPurchase } from "@/types/IPurchase.interface";

export type Mutations<S = State> = {
    [MutationTypes.SET_ACTIVE_CUSTOMER](state: S, payload: ICustomer): void;
    [MutationTypes.UPDATE_MEASUREMENT](state: S, payload: ICustomerToMeasurement[]): void;
    [MutationTypes.SET_REFERENTIAL_HEIGHT](state: S, payload: ICustomerReferential[]): void;
    [MutationTypes.SET_REFERENTIAL_WEIGHT](state: S, payload: ICustomerReferential[]): void;
    [MutationTypes.SET_REFERENTIAL_SHOES_SIZE](state: S, payload: ICustomerReferential[]): void;
    [MutationTypes.SET_REFERENTIAL_ORIGIN](state: S, payload: ICustomerReferential[]): void;
    [MutationTypes.SET_REFERENTIAL_MOTIVATION](state: S, payload: ICustomerReferential[]): void;
    [MutationTypes.SET_SEARCH_CUSTOMER](state: S, payload: IFilterAndSearchPurchase): void;
    [MutationTypes.RESET_CUSTOMER](state: S): void;
}

export const mutations: MutationTree<State> & Mutations = {
    [MutationTypes.SET_ACTIVE_CUSTOMER](state: State, customer: ICustomer) {
        state.activeCustomer = customer;
    },
    [MutationTypes.UPDATE_MEASUREMENT](state: State, measurements: ICustomerToMeasurement[]) {
        if (state.activeCustomer) {
            state.activeCustomer.customerToMeasurement = [...measurements];
        }
    },
    [MutationTypes.SET_REFERENTIAL_HEIGHT](state: State, payload: ICustomerReferential[]) {
        state.referentialHeight = payload;
    },
    [MutationTypes.SET_REFERENTIAL_WEIGHT](state: State, payload: ICustomerReferential[]) {
        state.referentialWeight = payload;
    },
    [MutationTypes.SET_REFERENTIAL_SHOES_SIZE](state: State, payload: ICustomerReferential[]) {
        state.referentialShoesSize = payload;
    },
    [MutationTypes.SET_REFERENTIAL_ORIGIN](state: State, payload: ICustomerReferential[]) {
        state.referentialOrigin = payload;
    },
    [MutationTypes.SET_REFERENTIAL_MOTIVATION](state: State, payload: ICustomerReferential[]) {
        state.referentialMotivation = payload;
    },
    [MutationTypes.SET_SEARCH_CUSTOMER](state: State, payload: IFilterAndSearchPurchase) {
        state.searchCustomer = payload;
    },
    [MutationTypes.RESET_CUSTOMER](state: State) {
        //state.customers = [] as ICustomer[];
        state.activeCustomer = undefined;
        state.referentialHeight = [] as ICustomerReferential[];
        state.referentialWeight = [] as ICustomerReferential[];
        state.referentialShoesSize = [] as ICustomerReferential[];
        state.referentialOrigin = [] as ICustomerReferential[];
        state.referentialMotivation = [] as ICustomerReferential[];
    }
}