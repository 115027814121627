import constantes from "@/constantes/constantes";
import { IStock } from "@/types/IStock";
import toolsService from "./tools.service";

const apiUrlPurchase = toolsService.getApiUrl() + "v1/stock";

export default {
    async loadStocks(): Promise<IStock[]> {
        if (toolsService.isUserConnected()) {
            const requestOptions = toolsService.getRequestOption("GET", "");
            return toolsService.fetchRessource(apiUrlPurchase, requestOptions, {}, "stock.service.ts", "loadStocks");
        } else {
            return new Promise(resolve => resolve([] as IStock[]));
        }
    },
    async updateStock(stocks: IStock[]): Promise<void> {

        for (const s of stocks) {
            s.quantity = parseFloat(s.quantity) as any;
        }

        const requestOptions = toolsService.getRequestOption("POST", JSON.stringify(stocks));
        console.log("requestOptions => ", requestOptions);

        // const input = [];
        // for (const s of stocks) {
        //     input.push({
        //         reference: s.reference,
        //         type: s.type,
        //         quantity: parseFloat(s.quantity),
        //         arrival: s.arrival,
        //         endOfLife: s.endOfLife,
        //         unit: s.unit,
        //         couleur: s.couleur,
        //         style: s.style,
        //         gamme: s.gamme,
        //         prixMA: s.prixMA,
        //         prixCH: s.prixCH,
        //         prixC3: s.prixC3,
        //         prixC2: s.prixC2,
        //         prixVE: s.prixVE,
        //         prixGI: s.prixGI,
        //         prixPA: s.prixPA,
        //     });
        // }

        // console.log("before update ", input);

        return toolsService.fetchRessource(apiUrlPurchase, requestOptions, stocks, "stock.service.ts", "updateStock");
    }
}